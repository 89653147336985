<template>
  <div class="CategoryNextButton">
    <!-- Back button -->
    <div class="BackButton">
      <div
        class="ArrowWrap"
        @click="onPrevClick()">
        <LeftIcon />
      </div>
    </div>

    <!-- Next button -->
    <div
      class="NextButton"
      @click="onNextClick()">
      <div class="TextWrap">
        <span
          v-if="nextButtonData.headline"
          class="Headline">{{ nextButtonData.headline }}</span>
      </div>
      <div class="IconWrap">
        <CategoryIcon
          v-if="nextButtonData.categoryID"
          :categoryID="nextButtonData.categoryID" />
        <CheckIcon v-else-if="nextButtonData.icon === 'check'" />
      </div>
      <div class="ArrowWrap">
        <RightIcon />
      </div>
    </div>
  </div>
</template>

<script>
import LeftIcon from '@/assets/svg/with-padding/angle-left.svg?inline'
import RightIcon from '@/assets/svg/with-padding/angle-right.svg?inline'
import CheckIcon from '@/assets/svg/with-padding/check.svg?inline'
import { mapGetters } from 'vuex'
import CategoryIcon from '@/components/Icons/CategoryIcon.vue'

export default {
  name: 'CategoryNextButton',
  props: {
    connectedTypes: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'currentScreening',
      'currentCategory',
      'categoriesAsArray',
      'addedTypesForCategory',
    ]),
    currentCategoryIndex() {
      return this.categoriesAsArray.findIndex(
        (x) => x.id === this.currentCategory.id,
      )
    },
    prevCategory() {
      if (this.currentCategoryIndex === 0) {
        return false
      }

      const prevCategory = this.categoriesAsArray[this.currentCategoryIndex - 1]

      return {
        title: this.mixWB(prevCategory.translation),
        path: {
          name: 'ScreeningsUpdateCategory',
          params: {
            screeningID: this.currentScreening.id,
            categoryID: prevCategory.id,
          },
        },
      }
    },
    nextCategory() {
      if (this.currentCategoryIndex === this.categoriesAsArray.length - 1) {
        return false
      }

      const nextCategory = this.categoriesAsArray[this.currentCategoryIndex + 1]

      return {
        title: this.mixWB(nextCategory.translation),
        categoryID: nextCategory.id,
        path: {
          name: 'ScreeningsUpdateCategory',
          params: {
            screeningID: this.currentScreening.id,
            categoryID: nextCategory.id,
          },
        },
      }
    },
    nextButtonData() {
      const data = {
        categoryID: '',
        icon: '',
        headline: '',
        text: '',
      }

      // Set as done
      if (!this.nextCategory.path) {
        data.icon = 'check'
        data.text = !this.addedTypesForCategory.length && !this.connectedTypes.length
          ? this.mixWB('SKIP_AND_DONE')
          : this.mixWB('DONE')
        return data
      }

      data.categoryID = this.nextCategory.categoryID
      data.text = this.nextCategory.title

      // Check to skip
      if (!this.addedTypesForCategory.length && !this.connectedTypes.length) {
        data.headline = this.mixWB('SKIP_AND_GO_TO')
        return data
      }

      data.headline = this.mixWB('GO_TO')

      return data
    },
  },
  methods: {
    onPrevClick() {
      if (!this.prevCategory.path) {
        this.$router.push({ name: 'ScreeningsUpdate', params: { screeningID: this.currentScreening.id } })
        return
      }

      this.$router.push(this.prevCategory.path)
    },
    onNextClick() {
      if (!this.addedTypesForCategory.length) {
        this.$store.dispatch('setSkippedCategory', this.$route.params.categoryID)
      }

      if (!this.nextCategory.path) {
        this.$router.push({ name: 'ScreeningsUpdate', params: { screeningID: this.currentScreening.id } })
        return
      }

      this.$router.push(this.nextCategory.path)
    },
  },
  components: {
    LeftIcon,
    RightIcon,
    CheckIcon,
    CategoryIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryNextButton
    display flex
    justify-content space-between
    padding 10px 0px
    margin-bottom 10px
    border-top 1px solid $color_grey_lighter
    border-bottom 1px solid $color_grey_lighter
    width 100%

  .BackButton
    border-right 1px dashed $color_grey_lighter
    padding-right 5px
    flex-shrink 0
    .ArrowWrap
      box(50px)
      padding 8px
      cursor pointer

  .NextButton
    display flex
    align-items center
    cursor pointer
    .IconWrap
      flex-shrink 0
      overflow hidden
      border-radius 4px
      position relative
      box(30px)
      padding 4px
      background-color $color_grey_lightest
      margin-right -7px
    .TextWrap
      margin-right 10px
      .Headline
        font-size 0.875rem
        text-transform uppercase
        color $color_grey
    .ArrowWrap
      flex-shrink 0
      box(50px)
      padding 8px
</style>
