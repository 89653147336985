<template>
  <div class="ScreeningsUpdateCategory">
    <div class="InnerPage">
      <CloseButton />

      <!-- Top -->
      <div class="Top">
        <div class="TitleWrap">
          <div class="Icon">
            <CategoryIcon :categoryID="currentCategory.id" />
          </div>
          <div class="TextWrap">
            <span class="Title">{{ currentCategoryTitle }}</span>
            <SingleUnitSelectedText />
          </div>
        </div>
      </div>

      <!-- Next button -->
      <CategoryNextButton :connectedTypes="connectedTypes" />

      <!-- Empty list -->
      <div
        ref="ListWrap"
        class="ListWrap">

        <div
          class="EmptyList"
          v-if="!connectedTypes.length && !addedTypesForCategory.length">
          <span>{{ mixWB('NOTHING_ADDED_YET') }}</span>
        </div>

        <!-- Has items -->
        <template v-else>
          <CategoryConnectedTypeList
            v-if="connectedTypes.length"
            :connectedTypes="connectedTypes" />

          <SectionHeadline
            v-if="connectedTypes.length"
            :text="mixWB('MATERIALS_FROM_THIS_BUILDING_PART')"
            hideBorder />

          <CategoryTypeList :categoryID="currentCategory.id" />
        </template>
      </div>

      <!-- Next button -->
      <div
        class="NextButton"
        v-if="showBottomNextButton">
        <CategoryNextButton :connectedTypes="connectedTypes" />
      </div>

      <router-link
        class="AddType"
        :to="{ name: 'ScreeningsUpdateCategoryAddType' }">
        <div class="IconWrap">
          <PlusIcon />
        </div>
      </router-link>

    </div>

  </div>
</template>

<script>
import CloseButton from '@/components/ScreeningItems/CloseButton.vue'
import CategoryIcon from '@/components/Icons/CategoryIcon.vue'
import CategoryTypeList from '@/components/ScreeningItems/CategoryTypeList.vue'
import { mapGetters } from 'vuex'
import { orderBy } from 'lodash-es'
import PlusIcon from '@/assets/svg/plus.svg?inline'
import CategoryConnectedTypeList from '@/components/ScreeningItems/CategoryConnectedTypeList.vue'
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import SingleUnitSelectedText from '@/components/SingleUnitSelectedText.vue'
import { getElementDocumentPos } from '@/globals/javascript/_util/util'
import CategoryNextButton from '@/views/CategoryNextButton.vue'

export default {
  name: 'ScreeningsUpdateCategory',
  data() {
    return {
      showBottomNextButton: false,
    }
  },
  computed: {
    ...mapGetters([
      'screeningTypes',
      'currentScreeningSelectedFilterUnitID',
      'currentCategory',
      'currentCategoryTitle',
      'addedTypesForCategory',
      'categoriesAsObject',
      'materialsAsObject',
    ]),
    connectedTypes() {
      const connectedMaterialIDs = this.currentCategory.connectedMaterialIDs || []
      const connectedTypeIDs = this.currentCategory.connectedTypeIDs || []

      const connectedTypes = []

      if (!connectedMaterialIDs.length && !connectedTypeIDs.length) {
        return connectedTypes
      }

      this.screeningTypes.forEach((type) => {
        // Check that type is added to this category
        if (this.currentCategory.id === type.categoryID) {
          return
        }

        // Check for filtered unit ID
        if (
          this.currentScreeningSelectedFilterUnitID
          && !type.unitIDs.includes(this.currentScreeningSelectedFilterUnitID)
        ) {
          return
        }

        const typeCategory = this.categoriesAsObject[type.categoryID]
        const resourceType = type.getResourceType()
        const resourceTypeMaterialID = resourceType?.materialIDs?.[0] || ''

        let isConnected = false
        // Check if material is connected to category
        if (connectedMaterialIDs.includes(resourceTypeMaterialID)) {
          isConnected = true
        }

        // Check if type is connected to category
        if (connectedTypeIDs.includes(resourceType.id)) {
          isConnected = true
        }

        // Check if from same area. Allow insulation to be gathered from all areas
        if (
          this.currentCategory.areaID !== 'AREA-3'
          && this.currentCategory.areaID !== typeCategory.areaID
          && this.currentCategory.id !== 'C-13' // Insulation
        ) {
          isConnected = false
        }

        if (isConnected) {
          connectedTypes.push(type)
        }
      })

      return orderBy(connectedTypes, ['categoryID', 'createdAt.seconds'], ['asc', 'asc'])
    },
  },
  methods: {
    setBottomNextButtonShowStatus() {
      const { bottom } = getElementDocumentPos(this.$refs.ListWrap)
      const windowHeight = window.innerHeight

      this.showBottomNextButton = bottom > windowHeight
    },
  },
  components: {
    CloseButton,
    CategoryIcon,
    CategoryTypeList,
    PlusIcon,
    CategoryConnectedTypeList,
    SectionHeadline,
    SingleUnitSelectedText,
    CategoryNextButton,
  },
  mounted() {
    this.setBottomNextButtonShowStatus()
    window.addEventListener('resize', this.setBottomNextButtonShowStatus, true)
  },
  updated() {
    this.setBottomNextButtonShowStatus()
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdateCategory
    page-wrap-outer-1()

  .InnerPage
    page-wrap-inner-1()

  .Top
    padding-bottom 20px
    .TitleWrap
      display flex
      align-items center
    .Icon
      display flex
      align-items center
      circle(40px)
      padding 5px
      border 1px solid $color_primary
      margin-right 10px
      svg
        box(100%)
        fill $color_grey
    .TextWrap
      .Title
        display block
        font-size 1.125rem
        font-weight bold
        text-transform uppercase

  .EmptyList
    margin-bottom 20px
    span
      font-style italic
      color $color_grey

  .AddTypeButtonWrap
    display block

  .AddType
    position fixed
    bottom 100px
    right 20px
    z-index 1
    circle(70px)
    background-color $color_primary
    padding 17px
    box-shadow 0px 3px 5px -1px rgba(0,0,0,0.2),
               0px 6px 10px 0px rgba(0,0,0,0.14),
               0px 1px 18px 0px rgba(0,0,0,0.12)
    svg
      fill #fff
    +below($desktop)
      bottom 200px

</style>
