<template>
  <div class="CategoryConnectedTypeList">
    <SectionHeadline
      :text="mixWB('MATERIALS_FROM_OTHER_BUILDING_PARTS')"
      hideBorder />

    <div class="ItemList">
      <div
        class="ItemWrap"
        v-for="type in connectedTypes"
        :key="type.id">
        <CategoryTypeListItem
          :type="type"
          :interConnectionGroupID="type.id"
          :isFirstInstance="true"
          isFirstInGroup
          isLastInGroup
          :isCurrentGroup="false"
          :outerInterConnection="false"
          clickToEdit
          isConnectedType />
      </div>
    </div>
  </div>
</template>

<script>
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import CategoryTypeListItem from '@/components/ScreeningItems/CategoryTypeListItem.vue'

export default {
  name: 'CategoryConnectedTypeList',
  props: {
    connectedTypes: {
      type: Array,
      required: true,
    },
  },
  components: {
    SectionHeadline,
    CategoryTypeListItem,
  },
}
</script>

<style lang="stylus" scoped>
  .CategoryConnectedTypeList
    margin-bottom 30px
    span
      display block

  .ItemWrap
    position relative
    margin-bottom 10px
    box-shadow 1px 2px 3px rgba(0, 0, 0, 0.2)
</style>
